<template>
  <div class="list-group-item">
    <div class="d-flex flex-row align-items-center">
      <img height="150" class="pr-4" :src="imgUrl" :alt="product.name" />
      <div class="d-inline-flex flex-row w-100">
        <h5 class="pr-4 mb-1">{{ product.name }}</h5>
        <p class="w-100 mb-1">{{ productDescription }}</p>
        <div class="d-flex controls">
          <div class="input-group number-spinner mb-2">
            <span class="input-group-btn">
              <button @click="removeProduct" class="btn btn-default" data-dir="dwn">-</button>
            </span>
            <input type="text" class="form-control text-center" v-model="product.quantity" />
            <span class="input-group-btn">
              <button @click="addProduct" class="btn btn-default" data-dir="up">+</button>
            </span>
          </div>
          <small v-if="!loading" class="px-4">{{ productPrice }} CHF</small>
          <div v-if="loading" class="spinner-border spinner-border-sm mx-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numberFormat from "../functions";
import _ from "lodash";

export default {
  props: ["product", "loading"],
  data: function () {
    return {
      adjustByAmount: 0,
      imgUrl: `/images/bt_thumbnail/${this.product.img}`,
    };
  },
  computed: {
    productPrice: function () {
      return numberFormat(this.product.price * this.product.quantity);
    },
    productDescription: function () {
      return this.product.description
        ? this.product.description.substring(0, 75) +
            this.product.description.substring(75) && "..."
        : "";
    },
  },
  methods: {
    adjustAmount: _.debounce(function () {
      if (process.env.NODE_ENV === "development") {
        console.log(
          `${this.adjustByAmount > 0 ? "Adding" : "Removing"} ${Math.abs(
            this.adjustByAmount
          )} entries.`
        );
      }

      if (this.adjustByAmount > 0) {
        this.$emit("add-product", this.product.id, this.adjustByAmount);
      } else if (this.adjustByAmount < 0) {
        this.$emit(
          "remove-product",
          this.product.id,
          Math.abs(this.adjustByAmount)
        );
      } else {
        return;
      }
      this.adjustByAmount = 0;
    }, 250),
    addProduct: function () {
      this.adjustByAmount++;
      this.adjustAmount();
    },
    removeProduct: function () {
      this.adjustByAmount--;
      this.adjustAmount();
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  align-items: center;

  .number-spinner {
    margin: 0 !important;
    flex-wrap: nowrap;
    align-items: stretch;

    input[type="text"] {
      min-width: 50px;
    }
  }
}
</style>
