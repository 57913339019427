import Vue from "vue";
import ShoppingCart from "@/components/ShoppingCart.vue";
import ShoppingCartItem from "@/components/ShoppingCartItem.vue";
import TextField from "@/components/TextField.vue";
import TextField2 from "@/components/TextField2.vue";

Vue.component("ShoppingCart", ShoppingCart);
Vue.component("ShoppingCartItem", ShoppingCartItem);
Vue.component("TextField", TextField);
Vue.component("TextField2", TextField2);
