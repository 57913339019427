<template>
  <div>
    <div class="list-group" v-if="cart">
      <shopping-cart-item
        v-for="product in cart.products"
        v-show="product.quantity"
        :product="product"
        :loading="loading"
        :key="product.id"
        @add-product="addProduct"
        @remove-product="removeProduct"
      ></shopping-cart-item>
      <div class="list-group-item list-group-item-dark">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">Total</h5>
          <small v-if="!loading">{{ totalCost }} CHF</small>
          <div v-show="loading" class="spinner-grow spinner-grow-sm mx-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import numberFormat from "../functions";

export default {
  props: ["customerId"],
  data: function () {
    return {
      cart: {},
      loading: false,
    };
  },
  methods: {
    addProduct: function (productId, amount = 1) {
      this.loading = true;
      axios
        .get(
          `/?rex-api-call=add_cart&customer_id=${this.customerId}&product_id=${productId}&quantity=${amount}`
        )
        .then((response) => {
          this.cart = response.data;
          this.loading = false;
        });
    },
    removeProduct: function (productId, amount = 1) {
      this.loading = true;
      axios
        .get(
          `/?rex-api-call=add_cart&customer_id=${this.customerId}&product_id=${productId}&quantity=${amount}&remove=true`
        )
        .then((response) => {
          this.cart = response.data;
          this.loading = false;
        });
    },
    onChange: function (name, value) {
      console.log(name, "change to", value);
    },
  },
  created: function () {
    this.loading = true;
    axios
      .get(`/?rex-api-call=get_cart&customer_id=${this.customerId}`)
      .then((response) => {
        this.cart = response.data;
        this.loading = false;
      });
  },
  computed: {
    totalCost: function () {
      return numberFormat(this.cart ? this.cart.totalCost : 0);
    },
  },
};
</script>
