<template>
  <input type="text" v-model="value" @input="onInput" @change="onChange" />
</template>

<script>
import _ from "lodash";

export default {
  props: ["name", "default", "type"],
  data: function () {
    return {
      value: this.default,
    };
  },
  methods: {
    onChange: _.debounce(function () {
      this.$emit("change", this.name, this.value);
    }, 150),
    onInput: function () {
      this.$emit("input", this.name, this.value);
    },
  },
  watch: {
    value: function (newVal, oldVal) {
      switch (this.type) {
        case "int":
          if (!isNaN(newVal)) {
            this.value = newVal;
          } else {
            this.value = oldVal;
          }
          break;
        default:
          this.value = newVal;
      }
      if (this.value != oldVal) {
        this.onChange();
      }
    },
  },
};
</script>

<style>
</style>
