function numberFormat(
  num: number,
  decPlaces: number = 2,
  decSep: string = ",",
  thouSep: string = "'"
) {
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = !decSep ? "." : decSep);
  thouSep = !thouSep ? "," : thouSep;
  let sign = num < 0 ? "-" : "";
  let i: string = String(
    // @ts-ignore
    parseInt((num = Math.abs(Number(num) || 0).toFixed(decPlaces)))
    );
  // @ts-ignore
  let j: number = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        // @ts-ignore
        Math.abs(num - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
}

export default numberFormat;
