




















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import lodash from "lodash";
import axios from "axios";

@Component
export default class TextField extends Vue {
  // Application state for axios instance in desperate need of a constructor.
  // Yes, it sure does, Dominik. Now back to Clara with the weather - it's "warm".

  @Prop(Number) public readonly id?: number;
  @Prop(String) public readonly type?: string;
  @Prop(String) public readonly textProp!: string;
  @Prop(Boolean) public readonly loadingProp?: boolean;
  @Prop(Boolean) public readonly successProp?: boolean;

  private textState: string = this.textProp;

  private timing = 5000;

  private debounceFire = lodash.debounce(this.fireUpdate, this.timing);

  private inputType = "text";

  private checkedType = this.checkType();

  public axiosInstance = axios.create({
    baseURL: "http://localhost:5000/",
  });

  public checkType() {
    let retVal = "";
    switch (this.type?.toLowerCase()) {
      case "value":
        retVal = "ProductValue";
        this.inputType = "number";
        break;
      case "price":
        retVal = "ProductPrice";
        this.inputType = "number";
        break;
      case "name":
        retVal = "ProductName";
        break;
    }
    return retVal;
  }

  public checkInputValidity() {
    let retVal = false;
    this.checkType();
    if (this.textState != "") {
      if (this.inputType == "number") {
        // Check through parsing.
        // Please do.
        retVal = true;
      } else {
        retVal = true;
      }
    }
    return retVal;
  }

  public onInputChange() {
    this.$emit("loading", true);
    if (this.checkInputValidity()) {
      this.debounceFire();
    } else {
      this.$emit("loading", false);
      this.$emit("success", false);
    }
  }

  public fireUpdate() {
    this.axiosInstance
      .put("Test?Id=" + this.id + "&" + this.checkedType + "=" + this.textState)
      .then(function (response) {
        this.loadingProp = false;
        if (response.status == 200) {
          this.successProp = true;
        } else {
          this.successProp = false;
        }
      });
  }
}
